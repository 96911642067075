<template>
  <b-sidebar
    id="create-subject-sidebar"
    hide-footer
    right 
    ref="createSubjectSidebar"
    bg-variant="white"
    backdrop
  >
    <div class="p-2">
      <b-form>
        <h2 class="m-0 text-colorBlack">Add Subject to class</h2>
        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bold text-colorBlack">Subject Name</span>
            <b-form-group class="mb-0">
              <b-form-input
                id="subjectName"
                placeholder="Subject Name"
                v-model="name"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bold text-colorBlack">Subject Code</span>
            <b-form-group class="mb-0">
              <b-form-input
                id="subjectCode"
                placeholder="Subject Code"
                v-model="code"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bold text-colorBlack"> Class </span>
            <b-form-group class="mb-0">
              <v-select label="name" v-model="classe" placeholder="Select Class" :options="classes" autoscroll :clearable="true"></v-select>          
            </b-form-group>
          </b-col>
        </b-form-row>


        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bold text-colorBlack"> Teacher </span>
            <b-form-group class="mb-0">
              <v-select label="name" v-model="teacher" placeholder="Select Teacher" :options="teachers" autoscroll :clearable="true"></v-select>          
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>

      <b-form-group>
        <b-button
          block
          type="submit"
          variant="colorBlue"
          style="margin-top: 30px"
          pill
          @click="submit()"
        >
          Add
        </b-button>
      </b-form-group>
    </div>
  </b-sidebar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  props: {},
  mixins: [util],
  data() {
    return {
      name: "",
      code: "",
      classe: "",
      classes: [],
      teachers: [],
      teacher: "",
    };
  },
  async mounted() {
    const res_class = await this.getClassDropDown();
    this.classes = res_class.data;

    const res_teacher = await this.getTeachersDropDown();
    this.teachers = res_teacher.data;
  },
  methods: {
    ...mapActions({
      createSubject: "appData/createSubject",
      getClassDropDown: "appData/getClassDropDown",
      getTeachersDropDown: "appData/getTeachersDropDown",
    }),


    async submit() {
      try {
        const formData = new FormData();
        formData.append("name", this.name);        
        formData.append("code", this.code);        
        formData.append("classes", this.classe.id);        
        formData.append("teacher", this.teacher.id);        
        formData.append("institute", this.getLoggedInUser.institute);        
        formData.append("created_by", this.getLoggedInUser.id); 
        formData.append("updated_by", this.getLoggedInUser.id); 

        const res = await this.createSubject(formData);
        if (res.status === 201) {
          this.$swal({
            title: "Subject created successfully",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$refs.createSubjectSidebar.hide();
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },

    reset() {
      this.name = "";      
      this.code = "";      
      this.classe = "";      
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" })
  },
};
</script>

<style scoped></style>
